.todos-single{
    display: flex;
    width: 29.5%;
    border-radius: 5px;
    padding: 20px;
    margin-top: 15px;
    background-image: url("https://img.freepik.com/free-photo/crumbled-yellow-paper-background-close-up_60487-2390.jpg?size=626&ext=jpg");
}

.todos-single-text{
    flex: 1;
    padding: 5px;
    border: none;
    font-size: 20px;
}

.todos-single-text:focus{
    outline: none;
}

.icon{
    margin-left: 10px;
    font-size: 25px;
    cursor: pointer;
}

@media (max-width: 700px) {
    .input {
      width: 95%;
    }
    .todos {
      width: 95%;
    }
    .todos-single {
      width: 100%;
    }
  }

  @media (max-width: 1200px) {
    .todos-single {
      width: 40%;
    }
  }